@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto Condensed', -apple-system;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d3ebf2;
}